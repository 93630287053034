import { useRpcClient } from '@gain/api/swr'
import { Issue, RpcMethodMap } from '@gain/rpc/cms-model'
import { isJsonRpcError, isJsonRpcValidationErrorWithIssues } from '@gain/rpc/utils'
import { IncludeStartsWith } from '@gain/utils/typescript'
import { yupResolver } from '@hookform/resolvers/yup'
import { flatMapDeep, get, setWith } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useCallback, useRef, useState } from 'react'
import { Noop, Path, useForm } from 'react-hook-form'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { DefaultValues } from 'react-hook-form/dist/types/form'
import * as yup from 'yup'
import { ObjectShape } from 'yup'

import InputFormWarningsAlert from './input-form-warnings-alert'

export interface InputFormAPI<GetMethod, UpdateMethod, CreateMethod, Item extends FieldValues> {
  getMethod?: GetMethod
  updateMethod?: UpdateMethod
  publishMethod?: IncludeStartsWith<keyof RpcMethodMap, 'data.publish' | 'cms.publish'>
  unPublishMethod?: IncludeStartsWith<keyof RpcMethodMap, 'data.unpublish' | 'cms.unpublish'>
  validateMethod?: IncludeStartsWith<keyof RpcMethodMap, 'data.validate' | 'cms.validate'>
  deleteMethod?: IncludeStartsWith<keyof RpcMethodMap, 'data.delete' | 'cms.delete'>
  createMethod?: CreateMethod

  archiveMethod?: IncludeStartsWith<keyof RpcMethodMap, 'data.archive' | 'cms.archive'>
  unArchiveMethod?: IncludeStartsWith<keyof RpcMethodMap, 'data.unarchive' | 'cms.unarchive'>

  /**
   * A boolean flag that determines whether the system should always apply a patch when a blur event occurs.
   *
   * If set to true, patches will always be applied each time the user moves focus away from a specific element.
   * If set to false or omitted, patches will only happen if the field that blurs does not have any errors.
   */
  alwaysPatchOnBlur?: boolean
  validationSchema?: Partial<Record<keyof Item, yup.AnySchema>>
  defaultValues?: DefaultValues<Partial<Item>>
}

type RecordId = string | null | number

function parseRecordId(id: string | number): number {
  if (typeof id === 'number') {
    return id
  }

  return parseInt(id, 10)
}

/**
 * Recursively extracts all paths from a given object, flattened into an array of strings.
 */
function getPaths(obj: object, prefix = ''): string[] {
  return flatMapDeep(Object.keys(obj), (key) => {
    const path = prefix ? `${prefix}.${key}` : key
    return typeof obj[key] === 'object' ? getPaths(obj[key], path) : path
  })
}

export function useInputFormAPI<
  GetMethod extends IncludeStartsWith<keyof RpcMethodMap, 'data.get' | 'cms.get'>,
  UpdateMethod extends IncludeStartsWith<keyof RpcMethodMap, 'data.update' | 'cms.update'>,
  CreateMethod extends IncludeStartsWith<keyof RpcMethodMap, 'data.create' | 'cms.create'>,
  Item extends RpcMethodMap[GetMethod]['result'] & FieldValues,
  Params extends RpcMethodMap[UpdateMethod]['params']
>({
  getMethod,
  updateMethod,
  validateMethod,
  publishMethod,
  unPublishMethod,
  createMethod,
  deleteMethod,
  archiveMethod,
  unArchiveMethod,

  alwaysPatchOnBlur = false,
  validationSchema = {},
  defaultValues,
}: InputFormAPI<GetMethod, UpdateMethod, CreateMethod, Item>) {
  const [creating, setCreating] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [saving, setSaving] = useState(false)
  const [validating, setValidating] = useState(false)
  const [publishing, setPublishing] = useState(false)
  const [archiving, setArchiving] = useState(false)

  const [formIssues, setFormIssues] = useState<Issue[]>([])

  const rpcClient = useRpcClient<RpcMethodMap>()
  const fetchedRecordRef = useRef<Item>()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const form = useForm<Partial<Item>>({
    resolver: yupResolver(yup.object(validationSchema as ObjectShape)),
    defaultValues,
    // We validate on change so when we fetch the field onBlur the validation is already done
    mode: 'onChange',
  })

  /**
   * handleValidationIssues accepts an array of issues, splits them into errors
   * and warnings and sets the errors as actual form errors for direct visual
   * feedback.
   */
  const handleValidationIssues = useCallback(
    (issues: Issue[]) => {
      setFormIssues(issues)

      const errors = issues.filter(({ type }) => type !== 'warning')
      const warnings = issues.filter(({ type }) => type === 'warning')

      errors.forEach((issue) => {
        form.setError(issue.path as never, {
          message: issue.message,
        })
      })

      return { errors, warnings }
    },
    [setFormIssues, form]
  )

  const handleFetch = useCallback(
    async (recordId: RecordId): Promise<Item | undefined> => {
      if (!recordId || !getMethod) {
        return
      }

      // Fetch the record
      fetchedRecordRef.current = (await rpcClient({
        method: getMethod,
        params: {
          id: parseRecordId(recordId),
        },
      })) as Item

      // Reset the form with the just fetched record
      form.reset(fetchedRecordRef.current, {
        keepErrors: true,
      })

      return fetchedRecordRef.current
    },
    [form, getMethod, rpcClient]
  )

  const validateRecord = useCallback(
    async (
      recordId: RecordId,
      showWarnings?: boolean,
      callback?: () => void
    ): Promise<[Issue[], Issue[]]> => {
      if (!validateMethod || !recordId) {
        return [[], []]
      }

      form.clearErrors()
      setValidating(true)
      const issues = await rpcClient({
        method: validateMethod,
        params: {
          id: parseRecordId(recordId),
        },
      })
      const { errors, warnings } = handleValidationIssues(issues)

      if (errors.length === 0 && warnings.length > 0 && showWarnings) {
        enqueueSnackbar(undefined, {
          key: 'item-form-publish-warnings',
          preventDuplicate: true,
          content: (snackbarId) => (
            <InputFormWarningsAlert
              forcePublish={() => handlePublish(recordId, true, callback)}
              onClose={() => closeSnackbar(snackbarId)}
              warnings={warnings}
            />
          ),
          variant: 'warning',
          persist: true,
        })
      }

      setValidating(false)

      return [errors, warnings]
    },
    [validateMethod, form, rpcClient, handleValidationIssues, enqueueSnackbar, closeSnackbar]
  )

  const handlePatch = useCallback(
    async (recordId: RecordId, partial: object): Promise<boolean> => {
      if (!recordId || !updateMethod) {
        return false
      }

      try {
        setSaving(true)

        await rpcClient({
          method: updateMethod,
          params: {
            id: parseRecordId(recordId),
            partial: partial,
          } as Params,
        })

        await handleFetch(recordId)
        // Validate in the background
        validateRecord(recordId)

        return true
      } catch (err) {
        if (isJsonRpcError(err)) {
          // If the error is a validation error, assign specific form errors
          if (isJsonRpcValidationErrorWithIssues(err)) {
            handleValidationIssues(err.data)
            return false
          }

          // On any different error, show a snackbar with a single error message
          enqueueSnackbar(err.message, {
            key: 'item-patch-error',
            preventDuplicate: true,
            variant: 'error',
          })
        }

        return false
      } finally {
        setSaving(false)
      }
    },
    [handleValidationIssues, enqueueSnackbar, handleFetch, rpcClient, updateMethod, validateRecord]
  )

  const handleFieldBlur = useCallback(
    (recordId: RecordId, unknownFieldName: unknown, formOnBlur: Noop, force?: boolean) =>
      async (): Promise<void> => {
        formOnBlur()

        const fieldName = unknownFieldName as Path<Partial<Item>>
        const { invalid, error } = form.getFieldState(fieldName)

        let fieldValue = form.getValues(fieldName)
        let hasError = Boolean(error)

        let executedSchema:
          | undefined
          | {
              errors: FieldErrors
              values: FieldValues
            }

        if (validationSchema) {
          // Overwrite the interface as they only say there are errors
          // We use this method directly so this one field is validated correctly + if yup is used to transform the value
          // (for example, string to number) it will be returned correctly
          executedSchema = (await form.control._executeSchema([fieldName])) as {
            errors: FieldErrors
            values: FieldValues
          }

          // Overwrite the field value as this one can be transformed through yup
          fieldValue = get(executedSchema.values, fieldName)
          // Make sure there was no error
          if (Object.keys(executedSchema.errors).length > 0) {
            hasError = true
          }
        }

        // Make sure there are no validation errors
        if ((!invalid && !hasError) || force || alwaysPatchOnBlur) {
          // Always use the provided fieldName for the initial partial, this makes
          // sure that if the group sends the parent field name it still works
          let partialUpdate = setWith({}, fieldName, fieldValue, Object)

          // Get all the dirty fields, after "handlePatch" the form is reset
          const dirtyFields = getPaths(form.formState.dirtyFields).filter(
            (field) => !field.startsWith(fieldName)
          )

          // If we have more dirty fields, add them to the patch
          if (dirtyFields.length > 0) {
            for (const dirtyFieldName of dirtyFields) {
              // Get the value from "executedSchema" if it's set, as that holds the latest, transformed data
              const dirtyFieldValue = executedSchema
                ? get(executedSchema.values, dirtyFieldName)
                : form.getValues(fieldName)

              // Add dirty field to the partial update
              partialUpdate = setWith(partialUpdate, dirtyFieldName, dirtyFieldValue, Object)
            }
          }

          await handlePatch(recordId, partialUpdate)
        }
      },
    [form, handlePatch, validationSchema, alwaysPatchOnBlur]
  )

  const handlePublish = useCallback(
    async (recordId: RecordId, ignoreWarnings = false, callback?: () => void): Promise<void> => {
      if (!recordId || !publishMethod) {
        return
      }

      // Submit form (will validate)
      await form.handleSubmit(async () => {
        try {
          setPublishing(true)

          const [errors, warnings] = await validateRecord(recordId, !ignoreWarnings, callback)

          // If we have issues then don't publish
          if (errors.length > 0 || (warnings.length > 0 && !ignoreWarnings)) {
            return
          }

          await rpcClient({
            method: publishMethod,
            params: {
              id: parseRecordId(recordId),
            },
          })

          await handleFetch(recordId)

          callback?.()
        } catch (err) {
          if (isJsonRpcError(err)) {
            enqueueSnackbar(err.message, {
              key: 'item-publish-error',
              preventDuplicate: true,
              variant: 'error',
            })
          }
        } finally {
          setPublishing(false)
        }
      })()
    },
    [publishMethod, form, validateRecord, rpcClient, handleFetch, enqueueSnackbar]
  )

  const handleUnPublish = useCallback(
    async (recordId: RecordId, callback?: () => void): Promise<boolean> => {
      if (!recordId || !unPublishMethod) {
        return false
      }

      setPublishing(true)

      try {
        await rpcClient({
          method: unPublishMethod,
          params: {
            id: parseRecordId(recordId),
          },
        })

        await handleFetch(recordId)
        callback?.()

        return true
      } catch (err) {
        if (isJsonRpcError(err)) {
          enqueueSnackbar(err.message, {
            key: 'item-un-publish-error',
            preventDuplicate: true,
            variant: 'error',
          })
        }

        return false
      } finally {
        setPublishing(false)
      }
    },
    [unPublishMethod, rpcClient, handleFetch, enqueueSnackbar]
  )

  const handleArchive = useCallback(
    async (recordId: RecordId): Promise<boolean> => {
      if (!recordId || !archiveMethod) {
        return false
      }

      setArchiving(true)

      try {
        await rpcClient({
          method: archiveMethod,
          params: {
            id: parseRecordId(recordId),
          },
        })

        await handleFetch(recordId)

        return true
      } catch (err) {
        if (isJsonRpcError(err)) {
          enqueueSnackbar(err.message, {
            key: 'item-archive-error',
            preventDuplicate: true,
            variant: 'error',
          })
        }

        return false
      } finally {
        setArchiving(false)
      }
    },
    [archiveMethod, rpcClient, handleFetch, enqueueSnackbar]
  )

  const handleUnArchive = useCallback(
    async (recordId: RecordId): Promise<boolean> => {
      if (!recordId || !unArchiveMethod) {
        return false
      }

      setArchiving(true)

      try {
        await rpcClient({
          method: unArchiveMethod,
          params: {
            id: parseRecordId(recordId),
          },
        })

        await handleFetch(recordId)

        return true
      } catch (err) {
        if (isJsonRpcError(err)) {
          enqueueSnackbar(err.message, {
            key: 'item-un-archive-error',
            preventDuplicate: true,
            variant: 'error',
          })
        }

        return false
      } finally {
        setArchiving(false)
      }
    },
    [unArchiveMethod, rpcClient, handleFetch, enqueueSnackbar]
  )

  const handleDelete = useCallback(
    async (recordId: RecordId, params?: object, callback?: () => void): Promise<boolean> => {
      if (!recordId || !deleteMethod) {
        return false
      }

      setDeleting(true)

      try {
        await rpcClient({
          method: deleteMethod,
          params: {
            id: parseRecordId(recordId),
            ...params,
          },
        })

        callback?.()

        return true
      } catch (err) {
        if (isJsonRpcError(err)) {
          enqueueSnackbar(err.message, {
            key: 'item-page-delete-error',
            preventDuplicate: true,
            variant: 'error',
          })
        }

        return false
      } finally {
        setDeleting(false)
      }
    },
    [deleteMethod, rpcClient, enqueueSnackbar]
  )

  const handleCreate = useCallback(
    async (
      params: RpcMethodMap[CreateMethod]['params'],
      callback?: (response: RpcMethodMap[CreateMethod]['result']) => void
    ): Promise<boolean> => {
      if (!params || !createMethod) {
        return false
      }

      setCreating(true)

      try {
        const response = await rpcClient({
          method: createMethod,
          params,
        })

        callback?.(response)

        return true
      } catch (err) {
        if (isJsonRpcError(err)) {
          if (isJsonRpcValidationErrorWithIssues(err)) {
            err.data.forEach((issue: Issue) => {
              form.setError(issue.path as never, {
                message: issue.message,
              })
            })
          } else {
            enqueueSnackbar(err.message, {
              key: 'item-page-create-error',
              preventDuplicate: true,
              variant: 'error',
            })
          }
        }

        return false
      } finally {
        setCreating(false)
      }
    },
    [createMethod, rpcClient, form, enqueueSnackbar]
  )

  return {
    form,
    issues: formIssues,

    fetchedRecord: fetchedRecordRef.current,

    busy: saving || publishing || deleting || archiving || creating,
    saving,
    publishing,
    validating,
    deleting,
    archiving,
    creating,

    fetch: handleFetch,
    patch: handlePatch,
    onBlur: handleFieldBlur,

    publish: handlePublish,
    unPublish: handleUnPublish,
    delete: handleDelete,
    archive: handleArchive,
    unArchive: handleUnArchive,
    create: handleCreate,
  }
}
