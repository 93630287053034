import { Asset, AssetListItem } from '@gain/rpc/cms-model'
import { AssetProfileType } from '@gain/rpc/shared-model'

export const GENERAL_INFO = 'generalInfo'
export const ALIASES = 'aliases'
export const TAGS = 'tags'
export const SHAREHOLDERS = 'shareholders'
export const COMPETITORS = 'competitors'
export const MARKET = 'market'
export const RATING = 'rating'
export const CONS = 'cons'
export const MANAGERS = 'managers'
export const PROS = 'pros'
export const SOURCES = 'sources'
export const DESCRIPTION = 'description'
export const SEGMENTATION = 'segmentation'
export const SEGMENTS = 'segments'
export const FINANCIAL_FOOT_NOTES = 'financialFootnotes'
export const LEGAL_ENTITIES = 'legalEntities'

export function isAsset(value: Asset | AssetListItem): value is Asset {
  return 'profileInConversion' in value && !('similarity' in value)
}

export function hasAssetEverBeenPublished(asset: Asset | AssetListItem): boolean {
  return isAsset(asset) ? !!asset.publishedAt : !!asset.publicationDate
}

export function isAssetArchived(asset: Asset | AssetListItem): boolean {
  // an asset can only be archived if it has been published before
  const isPublishedBefore = hasAssetEverBeenPublished(asset)
  // asset.live means archived or not
  // asset.profileLive means published or not
  return !asset.live && !asset.profileLive && isPublishedBefore
}

export function canEditAsset(asset: Asset | AssetListItem): boolean {
  if (asset.profileType === AssetProfileType.Automated) {
    return false
  }

  if (isAssetArchived(asset)) {
    return false
  }

  return !asset.profileLive
}

export function isAssetProfileFull(asset: Asset | AssetListItem): boolean {
  return asset.profileType === AssetProfileType.Full
}
