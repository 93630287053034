import Card from '@mui/material/Card'
import React from 'react'

import AssetsDataGrid from '../../features/assets-data-grid'
import LayoutDataGrid from '../../layout/data-grid'
import CreateAssetButton from './create-asset-dialog'

export default function AssetRoute() {
  return (
    <LayoutDataGrid title={'Companies'}>
      <Card>
        <AssetsDataGrid actions={[<CreateAssetButton key={'create-asset-button'} />]} />
      </Card>
    </LayoutDataGrid>
  )
}
