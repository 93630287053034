import ReactMarkdown from 'react-markdown'

interface InputFieldHelperTextProps {
  message?: string
}

/**
 * Used to render the error for any input field given a valid name. This helper
 * supports messages formatted with markdown. Markdown is sometimes used to
 * include URL's to other entity types for examples.
 */
export default function InputFieldHelperText({ message }: InputFieldHelperTextProps) {
  if (!message) {
    return null
  }

  return <ReactMarkdown children={message} />
}
